<template>
  <div :style="slidePosition" class="z-3 w-100 h-100">
    <div class="position-absolute text-warning particle"
      style="width:35%;left:25%;top:50%;transform:translate(-50%,-50%)">
      <inline-svg src="images/Slide3/circle-1.svg" aria-label="Particle nucleus"
        class="position-absolute p-50-50 circle-3 elipse3-1 " width="35%" />
      <inline-svg src="images/Slide3/circle-2.svg" aria-label="Particle inner ring"
        class="position-absolute p-50-50 circle-3 elipse3-2 " width="65%" />

      <inline-svg src="images/Slide3/circle-3.svg" aria-label="Particle outter ring"
        class="position-absolute p-50-50 circle-3 elipse3-3 " width="100%" />
    </div>
    <div iv class="position-absolute text-warning " style="width:42.5%;left:47.5%;top:47.5%;transform: translateY(-50%)">
      <h3 class="text-warning  font-vw-3-5 font-w-600 mb-0 fade3 " style="opacity:0;">
        1965
      </h3>
      <h6 class="text-warning  font-vw-2-5 font-w-600 fade3 my-0" style="opacity:0; ">
        AN AMBITIOUS VISION
      </h6>
      <p class="text-warning font-vw-1-35 mt-3 text3" style="opacity:0; ">
        Scientists at the University of British Columbia, Simon Fraser
        University, and the University of Victoria envisioned a grand project to
        build the world’s largest cyclotron particle accelerator to explore the
        frontiers of physics. Their original focus: mesons-- subatomic particles
        made of quark-antiquark pairs. The physics visionaries called their
        ambitious mission TRIUMF, the TRI-University Meson Facility—a name
        that’s lived on, past its original acronym, as TRIUMF became Canada’s
        particle accelerator centre.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    this.startLoop()
  },
  methods: {
    tl3(percentage) {
      let animation3 = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation3
        .add({
          targets: ".elipse3-1",
          width: ["0%", "35%"],
          duration: 1000,
          delay: 3000,
          easing: "easeInQuad"
        })
        .add({
          targets: ".elipse3-2",
          width: ["0%", "65%"],
          duration: 1000,
          delay: 300,
          easing: "easeOutElastic"
        })
        .add({
          targets: ".elipse3-3",
          width: ["0%", "100%"],
          duration: 500,
          delay: 300,
          easing: "easeOutElastic"
        })
        .add({
          targets: ".fade3",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 1000,
          easing: "easeInOutCubic",
          delay: (el, i) => 100 * i
        })
        .add({
          targets: ".text3",

          opacity: [0, 1],
          translateY: ["35%", "0%"],
          duration: 1000,
          easing: "easeInOutCubic"
        })
      animation3.seek(
        percentage <= 0.5
          ? animation3.duration * (2 * percentage)
          : animation3.duration * (1 - percentage)
      )
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.tl3(this.innerPosition)
    }
  }
}
</script>

<style></style>
